html{
    scroll-behavior: smooth;
    scroll-padding-top: 90px;
}

body {
    font-family: "Open Sans", sans-serif;
    line-height: 1.6;
    background: #fff;
    color: #000;
    position: relative;
}

.red-color {
    color: red !important;
}

.red-color:hover {
    color: white !important;
}

a {
    color: #eb1c24;
    text-decoration: none;
}

a:hover {
    color: #eb1c24;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Playfair Display", serif;
}

#title{
    font-weight: 600;
    font-size: 35px;
}

#subTitle{
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #1a1814;
}

#preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #1a1814;
    border-top-color: #eb1c24;
    border-bottom-color: #eb1c24;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: animate-preloader 1s linear infinite;
    animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
header {
    width: 100%;
    display: flex;
    flex-direction: column ;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    background: #fff;
    top: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  }
  
  #topbar,
  #bottombar {
    flex: 1;
  }
  
  #topbar.hidden {
    display: none;
  }
  
  #bottombar.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 999;
  }

#topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 4px;
    font-size: 14px;
    transition: all 0.5s;
    z-index: 996;
    background-color: rgba(45, 50, 144, 0.8);
}

#topbar .contact-info i {
    font-style: normal;
    color: #fff;
}

#topbar .contact-info i span {
    padding-left: 5px;
    color: #fff;
}

#topbar .languages ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    color: #cda45e;
}

#topbar .languages ul a {
    color: white;
}

#topbar .languages ul li+li {
    padding-left: 10px;
}

.nav-link{
    padding: 0 !important;
}

/*#topbar .languages ul li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: rgba(255, 255, 255, 0.5);
  content: "/";
}*/

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#bottombar {
    background: rgba(255, 255, 255, 0.6);
    /*  border-bottom: 1px solid rgba(255, 255, 255, 0.8);*/
    transition: all 0.5s;
    z-index: 997;
    padding: 5px 0;
    width: 100%;
}

#bottombar .logo {
    font-size: 28px;
    margin: 0;
    padding: 0;
    padding-right: 20px;
    line-height: 1;
    font-weight: 300;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
}

#bottombar .logo a {
    color: #fff;
}

#bottombar{
    position: relative;
}

#bottombar .navbar-expand-lg .navbar-nav .dropdown-menu.show{
    position: absolute;
    top: 100%;
    max-height: fit-content;
    border-radius: 0;
    border: none;
    box-shadow: 1px 5px 5px #2a2a2a87;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 8px;
}

@media (max-width:565.98px) {
    #bottombar .logo {
        padding-right: 0px;
    }

    #bottombar .navbar-expand-lg .navbar-nav .dropdown-menu.show{
        position: relative;
        box-shadow: none;
    }

    .navbar-collapse {
        padding: 16px 0;
    }

    .navbar .navbar-nav {
        margin: 16px auto !important;
    }

    .navbar img{
        width: 75%;
    }
}

/*#header .logo img {
  max-height: 40px;
}
*/
/*--------------------------------------------------------------
# Book a table button Menu
--------------------------------------------------------------*/
.book-a-table-btn {
    margin: 0 0 0 15px;
    border: 2px solid #eb1c24;
    color: #eb1c24;
    background-color: transparent;
    border-radius: 50px;
    padding: 8px 25px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    transition: 0.3s;
    font-weight: bold;
}

.book-a-table-btn:hover {
    background: #eb1c24;
    color: #fff;
    border: 2px solid #eb1c24;
}

@media (max-width: 992px) {
    .book-a-table-btn {
        margin: 0 15px 0 0;
        padding: 8px 20px;
    }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
    padding: 0;
    justify-content: flex-end;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

.navbar li {
    position: relative;
}

.navbar .navbar-nav a,
.navbar .navbar-nav a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 10px 0 10px 30px; */
    color: #2d3290;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.navbar .navbar-nav a i,
.navbar .navbar-nav a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}

.navbar .navbar-nav{
    gap:26px;
    justify-content:space-evenly;
}

.navbar .navbar-nav a:hover,
.navbar .navbar-nav .active,
.navbar .navbar-nav .active:focus,
.navbar .navbar-nav li:hover>a {
    color: #eb1c24;
}

.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
}

.navbar .dropdown ul li {
    min-width: 200px;
}

.navbar .dropdown ul a {
    padding: 10px 20px;
    color: #444444;
}

.navbar .dropdown ul a i {
    font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
    color: #cda45e;
}

.navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}

@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }

    .navbar .dropdown .dropdown:hover>ul {
        left: -100%;
    }
}

@media (max-width: 991.98px) {
    .navbar .navbar-nav{
        gap:20px;
        margin: 20px auto !important;
    }
}

/**
* Mobile Navigation 
*/


.mobile-nav-toggle {
    color: #2d3290;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }

    .navbar ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
    }

    .navbar ul.open{
        display: flex;
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    transition: 0.3s;
    z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 6px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #1a1814;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
    color: #cda45e;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
    margin: 15px;
}

.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
    min-width: 200px;
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
    color: #cda45e;
}

.navbar-mobile .dropdown>.dropdown-active {
    display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: 100vh;
    background: url("../img/herobg.png") top center;
    background-size: cover;
    position: relative;
    padding: 0;
}

#hero:before {
    content: "";
    background: rgba(73, 86, 160, 0.1);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

/* #hero .container {
    padding-top: 110px;
} */

@media (max-width: 992px) {
    #hero .container {
        padding-top: 98px;
    }
}

#hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
}

#hero h1 span {
    color: #eb1c24;
}

#hero h2 {
    color: #eee;
    margin-bottom: 10px 0 0 0;
    font-size: 22px;
    text-transform: uppercase;
}

.btns {
    margin-top: 30px;
}

.btn-book {
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    display: inline-block;
    padding: 12px 30px;
    border-radius: 50px;
    transition: 0.3s;
    line-height: 1;
    color: white;
    border: 2px solid #eb1c24;
}

.btn-menu:hover,
.btn-book:hover {
    background: #eb1c24;
    color: #fff;
}

#hero .btn-book {
    margin-left: 15px;
}

#hero .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#eb1c24 50%, rgba(235, 28, 36, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: relative;
    overflow: hidden;
}

#hero .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

#hero .play-btn::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate-btn 2s;
    animation: pulsate-btn 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(235, 28, 36, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
}

#hero .play-btn:hover::after {
    border-left: 15px solid #eb1c24;
    transform: scale(20);
}

#hero .play-btn:hover::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
}

@media (min-width: 1024px) {
    #hero {
        background-attachment: fixed;
    }
}

@media (max-width: 992px) {
    #hero .play-btn {
        margin-top: 30px;
    }
}

@media (max-height: 500px) {
    #hero {
        height: auto;
    }

    #hero .container {
        padding-top: 130px;
        padding-bottom: 60px;
    }
}

@media (max-width: 768px) {
    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 18px;
        line-height: 24px;
    }
}

@-webkit-keyframes pulsate-btn {
    0% {
        transform: scale(0.6, 0.6);
        opacity: 1;
    }

    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}

@keyframes pulsate-btn {
    0% {
        transform: scale(0.6, 0.6);
        opacity: 1;
    }

    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}

section {
    padding: 40px 0;
    overflow: hidden;
}

.section-bg {
    background-color: #1a1814;
}

.section-title {
    padding-bottom: 40px;
    z-index: 1;
    position: relative;
}

.section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
    font-family: "Poppins", sans-serif;
    display: none;
}

.section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.2);
    margin: 4px 10px;
}

.section-title p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    font-family: "Playfair Display", serif;
    color: #fff;
}

.room-img img {
    width: 100%;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
    background: url("../img/aboutbg.jpg") center center;
    background-size: cover;
    position: relative;
    padding: 80px 0;
}

.about:before {
    content: "";
    background: rgba(255, 255, 255, 0.9);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

.about .content{
    z-index: 1;
}

.about .content h3 {
    font-weight: 600;
    font-size: 35px;
}

.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    padding-bottom: 10px;
}

.about .content ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #eb1c24;
}

.about .content p{
    text-align: justify;
}

.about .content p:last-child {
    margin-bottom: 0;
}

.about-img {
    position: relative;
    transition: 0.5s;
    width: 350px;
    max-height: 350px;
}

.about-img img {
    max-width: 100%;
    border: 4px solid rgba(255, 255, 255, 0.2);
    position: relative;
    height: 100%;
    object-fit: cover;
}

.about-img::before {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 60px;
    height: 60px;
    z-index: 1;
    content: "";
    border-left: 5px solid #eb1c24;
    border-top: 5px solid #eb1c24;
    transition: 0.5s;
}

.about-img::after {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 60px;
    height: 60px;
    z-index: 2;
    content: "";
    border-right: 5px solid #eb1c24;
    border-bottom: 5px solid #eb1c24;
    transition: 0.5s;
}

.about-img:hover {
    transform: scale(1.03);
}

.about-img:hover::before {
    left: 10px;
    top: 10px;
}

.about-img:hover::after {
    right: 10px;
    bottom: 10px;
}

@media (min-width: 1024px) {
    .about {
        background-attachment: fixed;
    }
}

@media (max-width: 565.98px) {
    .about-img {
        margin-bottom: 24px;
    }
}

.why-us {
    background-color: #ffff;
    position: relative;
}

.why-us::before {
    content: '';
    background-color: #2d3290;
    width: 100%;
    height: 50vh;
    position: absolute;
    top: 0;
    z-index: 0;
}

.why-us .box {
    position: relative;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
    transition: all ease-in-out 0.3s;
    background: rgba(250, 250, 250, 1);
    border-radius: 5px;
    overflow: hidden;
}

.room-details {
    min-height: 110px;
}

.room-img {
    position: relative;
}

.why-us .box .room-img span {
    color: #eb1c24;
    position: absolute;
    right: 10px;
    bottom: 10px;
    background: rgba(250, 250, 250, 0.8);
    padding: 10px;
}

.why-us .box h4 {
    font-size: 24px;
    font-weight: 600;
    padding: 0;
    color: #2e3391;
}

.why-us .box p {
    color: #000;
    font-size: 14px;
    margin: 0;
    padding: 0;
    text-align: justify;
}

.room-details {
    padding: 10px;
}

.room-details .details-btn {
    background-color: rgba(235, 28, 38, 0.8);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    position: absolute;
    bottom: 20px;
    border: 1px solid rgba(235, 28, 38, 0.8);
    transition: all-ease-in-out 0.5s;
}

.room-details .details-btn:hover {
    border-color: red;
    background-color: transparent;
    color: red;
}

/*--------------------------------------------------------------
# Menu Section
--------------------------------------------------------------*/
.menu #menu-flters {
    padding: 0;
    margin: 0 auto 0 auto;
    list-style: none;
    text-align: center;
    border-radius: 50px;
}

.menu #menu-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 12px 10px 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    margin-bottom: 10px;
    transition: all ease-in-out 0.3s;
    border-radius: 50px;
    font-family: "Playfair Display", serif;
}

.menu #menu-flters li:hover,
.menu #menu-flters li.filter-active {
    color: #cda45e;
}

.menu #menu-flters li:last-child {
    margin-right: 0;
}

.menu .menu-item {
    margin-top: 50px;
}

.menu .menu-img {
    width: 70px;
    border-radius: 50%;
    float: left;
    border: 5px solid rgba(255, 255, 255, 0.2);
}

.menu .menu-content {
    margin-left: 85px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.menu .menu-content::after {
    content: "......................................................................" "...................................................................." "....................................................................";
    position: absolute;
    left: 20px;
    right: 0;
    top: -4px;
    z-index: 1;
    color: #bab3a6;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.menu .menu-content a {
    padding-right: 10px;
    background: #1a1814;
    position: relative;
    z-index: 3;
    font-weight: 700;
    color: white;
    transition: 0.3s;
}

.menu .menu-content a:hover {
    color: #cda45e;
}

.menu .menu-content span {
    background: #1a1814;
    position: relative;
    z-index: 3;
    padding: 0 10px;
    font-weight: 600;
    color: #cda45e;
}

.menu .menu-ingredients {
    margin-left: 85px;
    font-style: italic;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    color: rgba(255, 255, 255, 0.5);
}


.specials {
    overflow: hidden;
    background-color: #eaeeef;
}

.specials .section-title p {
    color: red;
}

.specials .nav-tabs {
    border: 0;
}

.specials .nav-link {
    border: 0;
    padding: 12px 15px !important;
    transition: 0.3s;
    color: #000;
    border-radius: 0;
    border-right: 2px solid #eb1c24;
    font-weight: 600;
    font-size: 15px;
}

.specials .nav-link:hover {
    color: #eb1c24;
}

.specials .nav-link.active {
    color: #fff;
    background: #eb1c24;
    border-color: #eb1c24;
}

.specials .nav-link:hover {
    border-color: #eb1c24;
}

.specials .tab-pane.active {
    -webkit-animation: fadeIn 0.5s ease-out;
    animation: fadeIn 0.5s ease-out;
}

.specials .details h3 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #000;
}

.specials .details p {
    color: #555;
    text-align: justify;
}


.specials .details p:last-child {
    margin-bottom: 0;
}

@media (max-width: 992px) {
    .specials .nav-link {
        border: 0;
        padding: 15px;
    }
}

.events {
    background: url(../img/events-bg1.jpg) center center no-repeat;
    background-size: cover;
    position: relative;
}

.events::before {
    content: "";
    background-color: rgba(45, 50, 144, 0.8);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.events .section-title h2 {
    color: #fff;
}

.events .content h3 {
    font-weight: 600;
    font-size: 26px;
    font-family: "Playfair Display", serif;
    color: #eb1c24;
}

.events .content p {
    color: #fff;
    font-style: italic;
    text-align: justify;
}

.events .content ul{
    list-style: none;
    color: #fff;
    padding: 0;
}

.events .content ul li{
    padding-bottom: 10px;
}


.events .container {
    position: relative;
}

@media (min-width: 1024px) {
    .events {
        background-attachment: fixed;
    }
}

.events .events-carousel {
    background: rgba(255, 255, 255, 0.08);
    padding: 30px;
}

.events .event-item {
    color: #fff;
}

.events .event-item h3 {
    font-weight: 600;
    font-size: 26px;
    color: #eb1c24;
}

.events .event-item .price {
    font-size: 26px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    margin-bottom: 15px;
    display: none;
}

.events .event-item .price span {
    border-bottom: 2px solid #cda45e;
}

.events .event-item ul {
    list-style: none;
    padding: 0;
}

.events .event-item ul li {
    padding-bottom: 10px;
}

.events .event-item ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #cda45e;
}

.events .event-item p:last-child {
    margin-bottom: 0;
}

.events .swiper-pagination {
    margin-top: 30px;
    position: relative;
}

.events .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.4);
    opacity: 1;
}

.events .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #eb1c24;
}

.book-a-table {
    background-color: #edebeb;
}

.book-a-table .section-title p {
    color: #eb1c24;
}

.book-a-table .php-email-form {
    width: 100%;
}

.book-a-table .php-email-form .form-group {
    padding-bottom: 8px;
}

.book-a-table .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
}

.book-a-table .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.book-a-table .php-email-form .error-message br+br {
    margin-top: 25px;
}

.book-a-table .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.book-a-table .php-email-form .loading {
    display: none;
    text-align: center;
    padding: 15px;
}

.book-a-table .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #cda45e;
    border-top-color: #1a1814;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

.book-a-table .php-email-form input,
.book-a-table .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    background: #ddd;
    border-color: #ddd;
    color: #a49b89;
}

.book-a-table .php-email-form input::-moz-placeholder,
.book-a-table .php-email-form textarea::-moz-placeholder {
    color: #a49b89;
}

.book-a-table .php-email-form input::placeholder,
.book-a-table .php-email-form textarea::placeholder {
    color: #a49b89;
}

.book-a-table .php-email-form input:focus,
.book-a-table .php-email-form textarea:focus {
    border-color: #cda45e;
}

.book-a-table .php-email-form input {
    height: 44px;
}

.book-a-table .php-email-form textarea {
    padding: 10px 12px;
}

.book-a-table button[type=submit] {
    background: #eb1c24;
    border: 0;
    padding: 10px 35px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
    margin-top: 35px;
}

.book-a-table button[type=submit]:hover {
    background: #2d3290;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
    overflow: hidden;
}

.testimonials .testimonial-item {
    box-sizing: content-box;
    min-height: 320px;
}

.testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50%;
    margin: -40px 0 0 40px;
    position: relative;
    z-index: 2;
    border: 6px solid rgba(255, 255, 255, 0.2);
}

.testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 45px;
    color: white;
}

.testimonials .testimonial-item h4 {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0 0 0 45px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
    color: #d3af71;
    font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
}

.testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 15px 0 15px;
    padding: 20px 20px 60px 20px;
    background: #26231d;
    position: relative;
    border-radius: 6px;
    position: relative;
    z-index: 1;
}

.testimonials .swiper-pagination {
    margin-top: 30px;
    position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.4);
    opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #eb1c24;
}

.gallery {
    background-color: #fff;
}

.gallery .section-title p {
    color: #eb1c24;
}

.gallery .lg-react-element {
    display: flex;
    flex-wrap: wrap;
}

.gallery .gallery-item {
    overflow: hidden;
    border: 3px solid #454035;
    height: 35vh;
    width: 25%;
}

.gallery .gallery-item img {
    transition: all ease-in-out 0.4s;
    height: 100%;
    width: 100%;
}

.gallery .gallery-item img:hover{
    transform: scale(1.1);
}

@media (max-width: 991.98px) {
    #hero {
        height: 77vh;
    }
    
    .gallery .gallery-item {
        width: 33.33%;
    }
}

@media (max-width: 565.98px) {    
    .gallery .gallery-item {
        width: 100%;
    }
}
  

/*--------------------------------------------------------------
# Chefs
--------------------------------------------------------------*/
.chefs .member {
    text-align: center;
    margin-bottom: 20px;
    background: #343a40;
    position: relative;
    overflow: hidden;
}

.chefs .member .member-info {
    opacity: 0;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.2s;
}

.chefs .member .member-info-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    transition: bottom 0.4s;
}

.chefs .member .member-info-content h4 {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 18px;
    color: #fff;
}

.chefs .member .member-info-content span {
    font-style: italic;
    display: block;
    font-size: 13px;
    color: #fff;
}

.chefs .member .social {
    position: absolute;
    left: 0;
    bottom: -38px;
    right: 0;
    height: 48px;
    transition: bottom ease-in-out 0.4s;
    text-align: center;
}

.chefs .member .social a {
    transition: color 0.3s;
    color: #fff;
    margin: 0 10px;
    display: inline-block;
}

.chefs .member .social a:hover {
    color: #cda45e;
}

.chefs .member .social i {
    font-size: 18px;
    margin: 0 2px;
}

.chefs .member:hover .member-info {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 212, 255, 0) 100%);
    opacity: 1;
    transition: 0.4s;
}

.chefs .member:hover .member-info-content {
    bottom: 60px;
    transition: bottom 0.4s;
}

.chefs .member:hover .social {
    bottom: 0;
    transition: bottom ease-in-out 0.4s;
}

.contact {
    background-color: #fff;
}

.contact .section-title p {
    color: #eb1c24;
}

.contact .info {
    width: 100%;
}

.contact .info i {
    font-size: 20px;
    float: left;
    width: 44px;
    height: 44px;
    background: #eb1c24;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

.contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
    font-family: "Poppins", sans-serif;
}

.contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #bab3a6;
}

.contact .info .open-hours,
.contact .info .email,
.contact .info .phone {
    margin-top: 40px;
}

.contact .php-email-form {
    width: 100%;
}

.contact .php-email-form .form-group {
    padding-bottom: 8px;
}

.contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #cda45e;
    border-top-color: #1a1814;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    background: #0c0b09;
    border-color: #625b4b;
    color: white;
}

.contact .php-email-form input::-moz-placeholder,
.contact .php-email-form textarea::-moz-placeholder {
    color: #a49b89;
}

.contact .php-email-form input::placeholder,
.contact .php-email-form textarea::placeholder {
    color: #a49b89;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
    border-color: #cda45e;
}

.contact .php-email-form input {
    height: 44px;
}

.contact .php-email-form textarea {
    padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
    background: #cda45e;
    border: 0;
    padding: 10px 35px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
    background: #d3af71;
}

@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

#footer {
    background: rgba(4, 53, 144, 0.8);
    color: #fff;
    font-size: 14px;
}

#footer .footer-top {
    background: rgba(4, 53, 144, 1);
    border-top: 1px solid rgba(4, 53, 144, 1);
    border-bottom: 1px solid rgba(4, 53, 144, 1);
    padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
    margin-bottom: 30px;
}


#footer .footer-top .footer-info h3 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 300;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
}

#footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Playfair Display", serif;
    color: #fff;
}

#footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #1b3668;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
    background: #eb1c24;
    color: #fff;
    text-decoration: none;
}

#footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #eb1c24;
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
    color: #eb1c24;
}

#footer .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #103374;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
    border: 1px solid #103374;
}

#footer .footer-top .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
    background: #103374;
    color: white;
}

#footer .footer-top .footer-newsletter form input[type=email]:focus-visible{
    outline:none
}

#footer .footer-top .footer-newsletter form input[type=submit] {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px 2px 20px;
    background: #eb1c24;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
}

#footer .footer-top .footer-newsletter form input[type=submit]:hover {
    background: #d32229;
}

#footer .row {
    /* text-align: center; */
    padding-top: 20px;
}

#footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
}

#footer .powered_by{
    text-align: end;
}


/*--------------------------------------------------------------
# Breadcrumb
--------------------------------------------------------------*/
.breadcrumsss{
    background: #2e3391;
    color: #fff;
}

.breadcrumsss .wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
}

.breadcrumsss .wrapper h2{
    color: #fff;
}

.breadcrumsss .wrapper .Breds span{
    position: relative;
    padding-right: 20px;
}

.breadcrumsss .wrapper .Breds span::after{
    content: "/";
    position: absolute;
    padding-left: 6px;
}

.breadcrumsss .wrapper .Breds span:last-child{
    color: #eb1c24;
}

.breadcrumsss .wrapper .Breds span:last-child:after{
    display: none;
}

.PageTitle{
    text-transform: capitalize;
}

@media (max-width: 565.98px) {  
    .breadcrumsss .wrapper {
        display: block;
    }
}

/* ------------------------------------------
# DETAIL PAGE 
--------------------------------------------*/

.detail-section{
    background-color: #fff;
}

/* ------------------------------------------
# TEAM PAGE 
--------------------------------------------*/
.team{
    background-color: #fff;
}

.team .chief{
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.team .members ul{
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
}

/* ------------------------------------------
# TEAM CARD 
--------------------------------------------*/
.team-card{
    display: flex;
    width: 440px;
    height: 180px;
    box-shadow: 0px 0px 8px #4a4a4a;
    border-radius: 8px;
    background-color: #103374;
    transition: all 0.5s ease;
}

.team-card:hover{
    transform: scale(1.05);
}

.team-card .card-img-top{
    width: 180px;
    height: 180px;
    padding: 12px;
    border-radius: 12px;
}

.team-card .card-img-top img{
    width: 100%;
    height: 100%;
    border-radius: 12px;
}

.team-card .card-body .title{
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    margin-top: 20px;
}

.team-card .card-body .post{
    font-size: 14px;
    font-weight: 500;
    color: #eb1c24;
}

@media (max-width:565.98px) {
    .team-card {
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .team-card .card-body{
        width: 100%;
        padding: 8px;
    }

    .team-card .card-img-top {
        width: 100%;
    }

    .BOD,.MNG{
        width: 100%;
    }
}

/* ------------------------------------------
# PRE-LOADER 
--------------------------------------------*/
.loader{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
    transition: all 0.5s ease-in;
}

/* ------------------------------------------
# SCROLL TO TOP 
--------------------------------------------*/

.back-to-top {
    position: fixed;
    /* visibility: hidden; */
    /* opacity: 0; */
    right: 15px;
    bottom: 15px;
    z-index: 996;
    width: 44px;
    height: 44px;
    border-radius: 50px;
    transition: all 0.4s;
    border: 2px solid #eb1c24;
}

.back-to-top i {
    font-size: 28px;
    color: #eb1c24;
    line-height: 0;
}

.back-to-top:hover {
    background: #eb1c24;
    color: #fff;
}

.back-to-top:hover i {
    color: #444444;
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
}

/* ------------------------------------------
# ROOM DETAILS 
--------------------------------------------*/
.details-room{
    margin: 30px auto 0 auto;
}

.details-room .img-wrapper img{
    width: 100%;
    height: 100%;
}

.details-room h4{
    font-size: 28px;
    font-weight: 600;
    padding: 0;
    color: #2e3391;
}

.details-room .price{
    color: #d32229;
    font-size: 20px;
    font-weight: 600;
}

.details-room .description{
    text-align: justify;
    padding: 0 20px;
}

.details-room .description ul {
    padding-left: 0;
}

.details-room .description ul li{
    list-style-type: none;
    padding-bottom: 8px;
}

.details-room .description ul li::before{
    content: ">>";
    font-weight: bold;
    color: #d32229;
    margin-right: 8px;

}

.details-room .description strong{
    color: #2e3391;
}

.details-room .book-a-table-btn{
    margin: 0;
    width: 230px;
    justify-content: center;
}

.details-room .details{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.details-room .details img{
    object-fit: contain;
    width: 100%;
}

@media (max-width: 565.98px) {    
    .details-room .description{
        margin-top: 20px;
    }
}